import * as React from "react";

import SEO from "../components/seo";
import Grid from "../components/grid";
import TextBlock from "../components/text-block";
import ExitButton from "../components/exit-button";

// markup
const ContactPage = () => {
  return (
    <>
      <SEO title="Contact" />
      <ExitButton />
      <Grid>
        <TextBlock>
          <h1 className="hide-on-desktop">Contact</h1>
          <p>Surya Commercial Properties<br/>232 Madison Avenue, Suite N°606<br/>New York, NY 10016<br/><a href="tel:1 6466929200">+1 646-692-9200</a></p>
          <p><a href="mailto:adi@suryacp.com">adi@suryacp.com</a></p>
        </TextBlock>
      </Grid>
    </>
  )
};

export default ContactPage;
